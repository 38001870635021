import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { classNames } from 'primereact/utils';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from './../_services';
import { useMutation } from "react-query";

export const ForgotPassword = () => {

    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const msgError = useRef(null);
    const navigate = useNavigate();
    const defaultValues = { email: '' };
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const { mutateAsync: getForgotPassword, isLoading: loading } = useMutation(forgotPassword, {
        onError: (res) => {
            setShowMessage(false);
            console.log(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        }, onSuccess: (data) => {
            setFormData(data);
            setShowMessage(true);
            reset();
        },
    });

    const onSubmit = async (data) => {

        console.log(data);
        setFormData(data);
        console.log(data.email);
        console.log("data");
        await getForgotPassword(data.email);
    };


    function onDialogClose() {
        setShowMessage(false);
        navigate("/login");
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={onDialogClose} /></div>;


    return (
        <>
            <div className="block-viewer">
                <div className="block-section">
                    <div className="block-content">
                        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                            <div className="card">
                                <Messages ref={msgError} />
                                <h5 className="text-center">Forgot Password</h5>
                                <div className="grid p-fluid">
                                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                                        <div className="flex align-items-center flex-column pt-6 px-3">
                                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                                            <h5>Forgot Password</h5>
                                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                                Please check <b>{formData.email}</b> for reset instructions.
                                            </p>
                                        </div>
                                    </Dialog>
                                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-envelope"></i>
                                            </span>
                                            <Controller name="email" control={control}
                                                rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText placeholder="Email Address" id={field.email} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                                )} />
                                        </div>
                                        {getFormErrorMessage('email')}                                     
                                        <div className="button-container">
                                            <Button type="submit" label="Submit" className="mt-2" loading={loading} />
                                            <br />
                                            <span>Have an account?<Link to="/login" className="p-link"> Login</Link></span>
                                        </div>

                                    </form>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


