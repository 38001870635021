import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { authAtom } from './../_state/auth';

export { useFetchWrapper };

function useFetchWrapper() {
    const [auth, setAuth] = useRecoilState(authAtom);
    const navigate = useNavigate();

    const baseUrl = "/api";

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };

     function request(method) {

         return (url, body) => {
             const requestOptions = {
                 method,
                 headers: authHeader(url)
             };

             if (body) {
                 requestOptions.headers['Content-Type'] = 'application/json';
                 requestOptions.body = JSON.stringify(body);
             }
             return fetch(url, requestOptions).then(handleResponse);

         }
     }

    // helper functions

    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        const token = auth?.token;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }

    // function fetchHandler(response) {
    //     if (response.ok) {
    //         return response.json().then(json => {
    //             // the status was ok and there is a json body
    //             return Promise.resolve({ json: json, response: response });
    //         }).catch(err => {
    //             // the status was ok but there is no json body
    //             return Promise.resolve({ response: response });
    //         });

    //     } else {
    //         return response.json().catch(err => {
    //             // the status was not ok and there is no json body
    //             throw new Error(response.statusText);
    //         }).then(json => {
    //             // the status was not ok but there is a json body
    //             throw new Error(json.error.message); // example error message returned by a REST API
    //         });
    //     }
    // }

    function handleResponse(response) {
        return response.text().then(text => {

            console.log("[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[");
            console.log(text);
            console.log("[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[");

            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status) && auth?.token) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('user');
                    setAuth(null);
                    navigate('/login');
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }


}