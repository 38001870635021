import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Messages } from 'primereact/messages';

import { ProgressBar } from 'primereact/progressbar';

import { InputText } from 'primereact/inputtext';
import { useQuery } from 'react-query';

import { getUsers } from '../../_services';


function Users() {
    // const emptyProduct = {
    //     id: null,
    //     name: '',
    //     image: null,
    //     description: '',
    //     category: null,
    //     price: 0,
    //     quantity: 0,
    //     rating: 0,
    //     inventoryStatus: 'INSTOCK',
    // };

    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [productDialog, setProductDialog] = useState(false);
    // const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    // const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    // const [user, setUser] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    // const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    // const toast = useRef(null);
    const dt = useRef(null);
    const msgError = useRef(null);



    const { } = useQuery('userlist', getUsers, {
        onError: (res) => {
            setIsLoading(false);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            setUsers(data);
        },
    });


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Products</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );




    if (isLoading) return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={msgError} />
                    {/* <Toolbar className="mb-4" left={leftToolbarTemplate} /> */}

                    <DataTable
                        ref={dt}
                        value={users}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        globalFilter={globalFilter}
                        emptyMessage="No users found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                        <Column field="firstName" header="First Name" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="lastName" header="Last Name" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="isMember" header="Member" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="role" header="Role" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="email" header="Email Address" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column header="Country" field="country" headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="church" header="Church" sortable headerStyle={{ width: '14%', minWidth: '8rem' }} />
                        <Column field="IsAccountVerified" header="Verified" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        <Column field="createdAt" header="Created At" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} />
                        {/* <Column body={actionBodyTemplate} /> */}
                    </DataTable>

                    {/* <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to delete <b>{user.lastName + ' ' + user.firstName}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to delete the selected users?</span>}
                        </div>
                    </Dialog> */}
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Users, comparisonFn);

