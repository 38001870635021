import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { authAtom } from './../../_state/auth';
import { useRecoilValue } from 'recoil';


export const PrviateRoute = ({ children }) => {
    let location = useLocation();
    const auth = useRecoilValue(authAtom);

    return auth ? (children) : (<Navigate to="/login" state={{ from: location }} />)
}


