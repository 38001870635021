import { useSetRecoilState } from 'recoil';
import { BehaviorSubject } from 'rxjs';
import { useFetchWrapper } from '../_helpers/fetch-wrapper';
import { useNavigate } from 'react-router-dom';
import { authAtom } from './../_state/auth';
import { usersAtom } from './../_state/users';



export { useUserActions };

function useUserActions() {
    let refreshTokenTimeout;
    const baseUrl = "/api";
    const fetchWrapper = useFetchWrapper();
    const setAuth = useSetRecoilState(authAtom);
    const setUsers = useSetRecoilState(usersAtom);
    const userSubject = new BehaviorSubject(null);
    const navigate = useNavigate();


    return {
        getcountDownloadByDateRange,
        getAllDownloadByBookType,
        getDownloadsByDateRange,
        totalDownloadsByUserid,
        getDownloadCountByUserId,
        getAllDownloads,
        deleteFile,
        uploadFile,
        getFiles,
        getFileById,
        deleteChurch,
        createChurch,
        updateChurch,
        getChurches,
        getChurchById,
        deleteLanguage,
        createLanguage,
        updateLanguage,
        getAllLanguages,
        getAccounts,
        getCountries,
        refreshToken,
        register,
        login,
        logout,
        verifyEmail,
        forgotPassword,
        validateResetToken,
        resetPassword,
        create,
        update,
        getById,
        migrationData,
        delete: _delete,
        getAll,
        user: userSubject.asObservable(),
        get userValue() { return userSubject.value }
    }

    //==================== start getDownloads ==================================

    function migrationData() {

        return fetchWrapper.get(`${baseUrl}/migrate/migrate-data`);
    }



    function getAllDownloadByBookType() {
        console.log("getAllDownloadByBookType");

        return fetchWrapper.get(`${baseUrl}/transaction/getAllDownloadByBookType`);
    }

    function getAllDownloads() {
        console.log("getAllDownloads");

        return fetchWrapper.get(`${baseUrl}/transaction/getAllDownloads`);
    }

    function totalDownloadsByUserid(id) {
        return fetchWrapper.get(`${baseUrl}/transaction/totalDownloadsByUserid/${id}`);
    }

    function getcountDownloadByDateRange(book, start, end) {

        const params = JSON.stringify({ book: book, start: start, end: end });
        console.log("=============getcountDownloadByDateRange===============");
        console.log(params);
        console.log("============getcountDownloadByDateRange===============");

        const result = fetchWrapper.get(`${baseUrl}/transaction/getcountDownloadByDateRange?book=${book}&start=${start}&end=${end}`);
        console.log(result);
        return (result);
    }

    //

    function getDownloadsByDateRange(start, end) {

        //  const params = JSON.stringify({ start: start, end: end });

        //http://localhost:4000/api/transaction/getDownloadsByDateRange?start=2022-01-01&end=2022-03-1
        //const result = fetchWrapper.get(`${baseUrl}/transaction/getDownloadsByDateRange`, params);
        // const result = fetchWrapper.get(`${baseUrl}/transaction/getDownloadsByDateRange/${start}/${end}`);
        const result = fetchWrapper.get(`${baseUrl}/transaction/getDownloadsByDateRange?start=${start}&end=${end}`);
        //  console.log(result);
        return (result);
    }
    function getDownloadCountByUserId(userId, start, end) {

        const result = fetchWrapper.get(`${baseUrl}/transaction/getDownloadCountByUserId?userId=${userId}&start=${start}&end=${end}`);
        // console.log(result);
        return (result);
    }


    //==================== end getDownloads   ==================================




    //==================== start Image Upload ==================================
    function deleteFile(id) {
        return fetchWrapper.delete(`${baseUrl}/file/delete/${id}`);
    }

    function uploadFile(params) {
        return fetchWrapper.post(`${baseUrl}/file/upload/`, params);
    }

    function getFiles() {
        return fetchWrapper.get(`${baseUrl}/file/getAll`);
    }

    function getFileById(id) {
        return fetchWrapper.get(`${baseUrl}/file/getById/${id}`);
    }

    //==================== end Image Upload ==================================


    //==================== start church ==================================
    function deleteChurch(id) {
        return fetchWrapper.delete(`${baseUrl}/church/delete/${id}`);
    }

    function createChurch(params) {



        return fetchWrapper.post(`${baseUrl}/church/create/`, params);
    }

    function updateChurch(id, params) {
        return fetchWrapper.put(`${baseUrl}/church/update/${id}`, params);
    }

    function getChurches() {
        return fetchWrapper.get(`${baseUrl}/church/getAll`);
    }

    function getChurchById(id) {
        return fetchWrapper.get(`${baseUrl}/church/getById/${id}`);
    }

    //==================== end church ==================================


    //==================== start translation ==================================
    function deleteLanguage(id) {
        return fetchWrapper.delete(`${baseUrl}/translation/delete/${id}`);
    }

    function createLanguage(params) {

        return fetchWrapper.post(`${baseUrl}/translation/create/`, params);
    }

    function updateLanguage(id, params) {
        return fetchWrapper.put(`${baseUrl}/translation/update/${id}`, params);
    }

    function getAllLanguages() {
        return fetchWrapper.get(`${baseUrl}/translation/getAll`);
    }

    //==================== end translation ==================================



    function getAccounts() {
        return fetchWrapper.get(`${baseUrl}/accounts/getAll`);
    }
    function getCountries() {
        return fetchWrapper.get(`${baseUrl}/country/getAll`);
    }

    function register(params) {
        return fetchWrapper.post(`${baseUrl}/accounts/register`, params);
    }

    function verifyEmail(token) {
        console.log('Verifying email: >> ' + token);

        return fetchWrapper.post(`${baseUrl}/accounts/verify-email`, { token });
    }

    function forgotPassword(email) {
        return fetchWrapper.post(`${baseUrl}/accounts/forgot-password`, { email });
    }

    function validateResetToken(token) {
        return fetchWrapper.post(`${baseUrl}/accounts/validate-reset-token`, { token });
    }

    function resetPassword({ token, password, confirmPassword }) {
        return fetchWrapper.post(`${baseUrl}/accounts/reset-password`, { token, password, confirmPassword });
    }

    function getById(id) {
        return fetchWrapper.get(`${baseUrl}/${id}`);
    }

    function create(params) {
        return fetchWrapper.post(baseUrl, params);
    }

    function update(id, params) {
        return fetchWrapper.put(`${baseUrl}/accounts/updateUser/${id}`, params)
            .then(user => {
                // update stored user if the logged in user updated their own record
                if (user.id === userSubject.value.id) {
                    // publish updated user to subscribers
                    user = { ...userSubject.value, ...user };
                    userSubject.next(user);
                }
                return user;
            });
    }

    // prefixed with underscore because 'delete' is a reserved word in javascript
    function _delete(id) {
        return fetchWrapper.delete(`${baseUrl}/${id}`)
            .then(x => {
                // auto logout if the logged in user deleted their own record
                if (id === userSubject.value.id) {
                    logout();
                }
                return x;
            });
    }


    function login(email, password) {

        return fetchWrapper.post(`${baseUrl}/accounts/authenticate`, { email, password })
            .then(user => {
                console.log('***************************')
                console.log(user);
                console.log('***************************')

                localStorage.setItem('resetToken', user.resetToken);
                localStorage.setItem('token', user.jwtToken);
                localStorage.setItem('user', JSON.stringify(user));
                setAuth(user);
            });
    }


    function refreshToken() {
        return fetchWrapper.post(`${baseUrl}/accounts/refresh-token`, {})
            .then(user => {
                // publish user to subscribers and start timer to refresh token
                userSubject.next(user);
                startRefreshTokenTimer();
                return user;
            });
    }
    function logout() {

        var user = localStorage.getItem("user");

        console.log(user);
        fetchWrapper.post(`${baseUrl}/accounts/revoke-token`, { user });
        stopRefreshTokenTimer();
        // remove user from local storage, set auth state to null and redirect to login page
        localStorage.removeItem('user');
        setAuth(null);
        userSubject.next(null);
        navigate('/en/dist');
    }

    function getAll() {
        return fetchWrapper.get(baseUrl).then(setUsers);
    }


    function startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(refreshToken, timeout);
    }

    function stopRefreshTokenTimer() {
        clearTimeout(refreshTokenTimeout);
    }
}
