import React from 'react'
import { Chart } from 'primereact/chart';

export const BarChart = ({ chartData, options }) => {
    return (
        <>
            <div className="card">
                <Chart type="bar" data={chartData} options={options} />
            </div>
        </>
    )
}
