import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { ProgressBar } from 'primereact/progressbar';

import {
    getLanguages, deleteLanguage, createLanguage, updateLanguage,
} from '../_services';
import { authAtom } from '../_state/auth';

export function Languages() {
    const msgError = useRef(null);
    const queryClient = useQueryClient();
    const auth = useRecoilValue(authAtom);
    const { mutateAsync: editChurch } = useMutation(updateLanguage);
    const { mutateAsync: addChurch } = useMutation(createLanguage);

    const { mutateAsync } = useMutation(deleteLanguage);
    const { isLoading, error, data } = useQuery('churches', getLanguages);

    const emptyLanguage = {
        id: null,
        name: '',

  };

    const [languageDialog, setLanguageDialog] = useState(false);
    const [deleteLanguageDialog, setDeleteLanguageDialog] = useState(false);
    const [deleteLanguagesDialog, setDeleteChurchesDialog] = useState(false);
    const [translation, setTranslation] = useState(emptyLanguage);
    const [selectedLanguages, setSelectedChurches] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
 
    const dt = useRef(null);

    const openNew = () => {
        setTranslation(emptyLanguage);
        setSubmitted(false);
        setLanguageDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setLanguageDialog(false);
    };

    const hideDeleteLanguageDialog = () => {
        setDeleteLanguageDialog(false);
    };

    const hideDeleteLanguagesDialog = () => {
      setDeleteChurchesDialog(false);
  };

    const saveLanguage = async () => {
        setSubmitted(true);



        console.log('=============SAVE===============');
        console.log(translation);
        console.log(translation.name);
        console.log('============SAVE===============');

    //  if (translation.name.trim()) {
        const _language = { ...translation };

        if (_language._id) {
          console.log(translation);
          _language.updated = Date.now();
    
          _language.name = translation.name;
            _language.id = translation._id;
          _language.token = auth.jwtToken;

            console.log('=============_language===============');
            console.log(_language); 
            console.log('============_language===============');

          await editChurch(_language);
          queryClient.invalidateQueries('churches');
      } else {
          
          _language.token = auth.jwtToken;

          await addChurch(_language);
          queryClient.invalidateQueries('churches');
          }
          setLanguageDialog(false);
          setTranslation(emptyLanguage);
     // }
  };

    const confirmDeleteLanguage = (language) => {
      setTranslation(language);
      setDeleteLanguageDialog(true);
  };

    const deleteTranslation = async () => {
      console.log(data);
      const _languages = data.filter((val) => val.id !== translation.id);

      console.log('=============DELETE===============');
      console.log(translation._id);
      console.log(_languages);
      console.log('============DELETE===============');

      await mutateAsync(translation._id);
      queryClient.invalidateQueries('churches');

      // setChurches(_languages);
      setDeleteLanguageDialog(false);
      setTranslation(emptyLanguage);
      msgError.current.show([{
          severity: 'success', detail: 'Language deleted successful', sticky: false, life: 5000,
      }]);
  };

    const deleteSelectedLanguages = () => {
      alert('2');
      const _church = data.filter((val) => !selectedLanguages.includes(val));
      console.log(selectedLanguages);
      console.log(_church);
      //  setChurches(_languages);
      setDeleteChurchesDialog(false);
      setSelectedChurches(null);
      msgError.current.show([{
          severity: 'success', detail: 'Language deleted successfully', sticky: false, life: 5000,
      }]);
  };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
      const _language = { ...translation };
      _language[`${name}`] = val;

      setTranslation(_language);
  };

    const leftToolbarTemplate = () => (
        <div className="my-2">
            <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
          {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedLanguages || !selectedLanguages.length} /> */}
      </div>
  );

    const rightToolbarTemplate = () => (

      <div className="col-12">
          <span className="block mt-2 md:mt-0 p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span>
      </div>

  );

    const actionBodyTemplate = (rowData) => (
        <div className="actions">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editLanguage(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteLanguage(rowData)} />
        </div>
    );

    const languageDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveLanguage} />
        </>
    );
    const deleteTranslationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteLanguageDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTranslation} />
        </>
    );
    const deleteTranslationsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteLanguagesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedLanguages} />
        </>
    );

    const editLanguage = (translation) => {
      const newlanguage = {};
  
      setTranslation({ ...translation });
  
      setLanguageDialog(true);
  };

    const onSelectionChange = (e) => {
        console.log(e.value);
      setSelectedChurches(e.value);
  };

 

    if (isLoading) return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />;

    if (error) return `An error has occurred: ${error.message}`;

    return (
        <div>
          <div className="feature-intro">
                <h3>Language List</h3>
          </div>
          <div className="col-12">

              <div className="card">
                  <Messages ref={msgError} />
                  <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />

                  <DataTable
                      ref={dt}
                      value={data}
                      selection={selectedLanguages}
                      onSelectionChange={onSelectionChange}
                      dataKey="id"
                      paginator
                      rows={5}
                      rowsPerPageOptions={[5, 10, 25]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} translations"
                      globalFilter={globalFilter}
                      emptyMessage="No language found."
                      responsiveLayout="scroll"
                  >
                      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                                          <Column field="name" header="Language" sortable headerStyle={{ width: '25%', minWidth: '10rem' }} />
                      <Column field="createdAt" header="Created Date" sortable headerStyle={{ width: '18%', minWidth: '10rem' }} />
                      <Column field="updatedAt" header="Update Date" sortable headerStyle={{ width: '18%', minWidth: '10rem' }} />

                      <Column body={actionBodyTemplate} />
                  </DataTable>

                    <Dialog visible={languageDialog} style={{ width: '450px' }} header="Language Details" modal className="p-fluid" footer={languageDialogFooter} onHide={hideDialog}>
 
                      <div className="field">
                            <label htmlFor="church">Language</label>
                          <InputText
                              id="church"
                              value={translation.name}
                              onChange={(e) => onInputChange(e, 'name')}
                              required
                              autoFocus
                              className={classNames({ 'p-invalid': submitted && !translation.name })}
                          />
                            {submitted && !translation.name && <small className="p-invalid">Language name is required.</small>}
                      </div>

                  </Dialog>

                  <Dialog visible={deleteLanguageDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTranslationDialogFooter} onHide={hideDeleteLanguageDialog}>
                      <div className="flex align-items-center justify-content-center">
                          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                          {translation && (
                              <span>
                                  Are you sure you want to delete
                                  <b>{translation.name}</b>
                                  ?
                              </span>
                          )}
                      </div>
                  </Dialog>

                  <Dialog visible={deleteLanguagesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTranslationsDialogFooter} onHide={hideDeleteLanguagesDialog}>
                      <div className="flex align-items-center justify-content-center">
                          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                          {translation && <span>Are you sure you want to delete the selected name?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
