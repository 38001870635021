import axios from 'axios';
import download from 'downloadjs';

//const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const baseUrl = "/api";

export class FileService {


    async DownloadTeevo(language, book, month, year) {
        return await axios.get(`${baseUrl}/transaction/getBookByName?language=${language}&name=${book}&month=${month}&year=${year}`);
    }

    async getByBookNames(language, book, month, year) {
        return await axios.get(`${baseUrl}/transaction/getBookByName?language=${language}&name=${book.name}&month=${month}&year=${year}`);
    }

    async getByBookName(language, book) {
        return await axios.get(`${baseUrl}/transaction/getBookByName?language=${language}&name=${book.name}`).then(res => res.data);

    }

    async getTeeVoLanguagesByBookName(book, month, year) {
        return await axios.get(`${baseUrl}/transaction/getTeeVoLanguagesByBookName?book=${book}&month=${month}&year=${year}`);
    }


    async getFiles() {
        return axios.get(`${baseUrl}/files/getAllFiles`).then(res => res.data);
    }

    async downloadFile(id, path, mimetype) {
        const result = await axios.get(`${baseUrl}/files/download/${id}`, { responseType: 'blob' });

        const split = path.split('/');
        const filename = split[split.length - 1];


        return download(result.data, filename, mimetype);
    }

    async countDownload(userid, fileid) {




        const post = { user: userid, file: fileid }
        await axios.post(`${baseUrl}/transaction/countDownload/`, post);
    }




    async getDownloadsByUserid(id) {


        const result = await axios.get(`${baseUrl}/transaction/getDownloadsByUserid/${id}`);

        console.log(result.data);
        return (result.data);
    }

    async getDownloadsByDateRange(start, end) {

        const result = await axios.get(`${baseUrl}/transaction/getDownloadsByDateRange`, { params: { start: start, end: end } });

        //  const result = await axios.get(`${baseUrl}/transaction/getDownloadsByDateRange/`, { params: { start: start, end: end }});

        console.log(result.data);
        return (result.data);
    }

    // function getDownloadsByDateRange(start, end) {

    async forgotPassword(email) {
        return await axios.post(`${baseUrl}/accounts/forgot-password`, { email });
    }

    async downloadTransaction(userid, fileid) {
        const post = { user: userid, file: fileid }
        await axios.post(`${baseUrl}/transaction/create/`, post);
    }

    async deleteFile(id) {
        console.log(id);

        await fetch(`${baseUrl}/files/delete/${id}`, {
            method: "GET"
        });
    }
}



export const singleFileUpload = async (data, options) => {

    console.log(options);
    console.log(data);


    try {
        await axios.post(baseUrl + '/singleFile', data, options);
    } catch (error) {
        throw error;
    }
}
export const getSingleFiles = async () => {
    try {
        const { data } = await axios.get(baseUrl + '/getSingleFiles');
        return data;
    } catch (error) {
        throw error;
    }
}

export const multipleFilesUpload = async (data, options) => {
    try {
        await axios.post(baseUrl + '/multipleFiles', data, options);
    } catch (error) {
        throw error;
    }
}
export const getMultipleFiles = async () => {
    try {
        const { data } = await axios.get(baseUrl + '/getMultipleFiles');
        return data;
    } catch (error) {
        throw error;
    }
}