import React, { useEffect } from 'react';
import { ProgressBar } from 'primereact/progressbar';

export const GlobalLoader = () => {


    useEffect(() => {
        alert();
    }, []);



    return (
        <div>

            <div className="col-12">
                <div className="cards">
                    <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                </div>
            </div>
        </div>
    )
}
