import React, { useEffect, useState, useRef } from 'react';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import queryString from 'query-string';
import { useUserActions } from './../_actions/user.actions';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';




export const ResetPassword = (props) => {
    const userActions = useUserActions();
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [token, setToken] = useState(null);

    const msgError = useRef(null);

    const TokenStatus = { Validating: 'Validating', Valid: 'Valid', Invalid: 'Invalid' }
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

    const defaultValues = { password: '', confirmPassword: '' }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });


    useEffect(() => {
        const { token } = queryString.parse(props.location.search);

        console.log(token);

        userActions.validateResetToken(token)
            .then((res) => {
                console.log(res);
                setToken(token);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch((err) => {
                console.log(err);
                setTokenStatus(TokenStatus.Invalid);
            });
    }, [TokenStatus.Invalid, TokenStatus.Valid, props.location.search, userActions]);




    const onSubmit = (data) => {
        setFormData(data);

        userActions.resetPassword(token, data.password, data.confirmPassword)
            .then(() => {
                setShowMessage(true);
                setFormData(data);

            })
            .catch(error => {
                msgError.current.show([{ severity: 'error', detail: error, sticky: false }]);
                console.log(error);
            });

        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };


    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;


    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );


    const bodyTemplate = () => {
        return (
            <>

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="field">
                        <span className="p-float-label">
                            <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />
                            )} />
                            <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password*</label>
                        </span>
                        {getFormErrorMessage('password')}
                    </div>

                    <div className="field">
                        <span className="p-float-label">
                            <Controller name="confirmPassword" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />
                            )} />
                            <label htmlFor="confirmPassword" className={classNames({ 'p-error': errors.confirmPassword })}>Password*</label>
                        </span>
                        {getFormErrorMessage('confirmPassword')}
                    </div>


                    <Button type="submit" label="Submit" className="mt-2" />
                </form>


            </>

        );
    }


    const getBody = () => {
        switch (tokenStatus) {
            case TokenStatus.Valid:
                return bodyTemplate();
            // eslint-disable-next-line no-fallthrough
            case TokenStatus.Invalid:
                return <div>Token validation failed, if the token has expired you can get a new one at the <Link to="forgot-password">forgot password</Link> page.</div>;
            case TokenStatus.Validating:
                return <div>Validating token...</div>;
            default:
            // do nothing
        }
    }

    return (
        <div>
            <div className="block-viewer">
                <div className="block-section">
                    <div className="block-content">
                        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                                <div className="flex align-items-center flex-column pt-6 px-3">
                                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                                    <h5>Forgot Password</h5>
                                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                        Please check <b>{formData.email}</b> for reset instructions.
                                    </p>
                                </div>
                            </Dialog>
                            <div className="flex justify-content-center">
                                <div className="card">
                                    <Messages ref={msgError} />
                                    <h5 className="text-center">Reset Password</h5>
                                    <div>{getBody()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )



}


