import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation, Routes } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './components/layout/AppTopbar';
import { AppFooter } from './components/layout/AppFooter';
import { AppMenu } from './components/layout/AppMenu';
import { AppConfig } from './components/layout/AppConfig';

import { Dashboard } from './components/Dashboard';

import { Download } from './components/Download';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { PrviateRoute } from './components/layout/PrviateRoute.js';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.css';
import { FilesList } from './components/FilesList';
import { GlobalLoader } from './components/GlobalLoader';
import { LandingPage } from './components/LandingPage';
import { Languages } from './components/Languages';
import { useRecoilValue } from 'recoil';
import { useIsFetching } from 'react-query';
import { authAtom } from './_state/auth';
import { UploadJsonFile } from './components/UploadJsonFile';
import { Churches } from './components/Churches';
import { Profile } from './components/profile/Index';
import { User } from './components/accounts/User';
import Users from './components/accounts/Users';

import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

import { Update } from './components/profile/Update';



const App = () => {
  const auth = useRecoilValue(authAtom);
  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('light')
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();
  const queryClient = new QueryClient();
  const isfetching = useIsFetching();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;


  useEffect(() => {
    console.log(auth);

    // if (!auth)  history.push("/login");

    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  }

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value)
  }

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode)
  }

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode)
  }

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  }

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      }
      else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    }
    else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  }

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  }

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }
  const isDesktop = () => {
    return window.innerWidth >= 992;
  }

  ///
  const menu = [
    (auth?.role === 'Admin' || auth?.role === 'Super Admin') && {
      label: 'Home',
      items: [
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
        { label: 'Church List', icon: 'pi pi-fw pi-home', to: 'admin/church' },
        { label: 'Languages', icon: 'pi pi-fw pi-home', to: 'admin/languages' },
        { label: 'File List', icon: 'pi pi-fw pi-check-square', to: '/admin/file-list' }

      ]
    },
    (auth?.role === 'Admin' || auth?.role === 'User' || auth?.role === 'Super Admin') && {
      label: 'Translations', icon: 'pi pi-fw pi-sitemap',
      items: [

        { label: 'Download', icon: 'pi pi-fw pi-globe', to: '/user/download' }

      ]
    },
    (auth?.role === 'Super Admin' || auth?.role === 'Admin') && {
      label: 'Administration', icon: 'pi pi-fw pi-sitemap',
      items: [
        //    { label: 'Profile', icon: 'pi pi-fw pi-globe', to: '/user/profile' },
        //     { label: 'Upload Country', icon: 'pi pi-fw pi-home', to: 'admin/upload' },
        { label: 'Migration', icon: 'pi pi-fw pi-globe', to: '/admin/migrate' },
        { label: 'Users', icon: 'pi pi-fw pi-home', to: '/admin/user-list' },
        { label: 'Loanding', icon: 'pi pi-fw pi-globe', to: '/en/dist' }
      ]
    }
  ];
  //
  const addClass = (element, className) => {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  const removeClass = (element, className) => {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light'
  });


  return (

    <div className={wrapperClass} onClick={onWrapperClick}>
      <QueryClientProvider client={queryClient}>
        <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
          mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />


        <div className="layout-sidebar" onClick={onSidebarClick}>
          <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
        </div>


        <div className="layout-main-container">
          <div className="layout-main">
            {isfetching ? <GlobalLoader /> : null}
            <Routes>

              <Route path="/" element={<PrviateRoute> <Dashboard colorMode={layoutColorMode} /> </PrviateRoute>}></Route>
              <Route path="/admin/dashboard" element={<PrviateRoute> <Dashboard colorMode={layoutColorMode} /> </PrviateRoute>}></Route>
              <Route path="/admin/file-list" element={<PrviateRoute> <FilesList /> </PrviateRoute>}></Route>
              <Route path="/user/download" element={<PrviateRoute> <Download /> </PrviateRoute>}></Route>
              <Route path="/admin/upload" element={<PrviateRoute> <UploadJsonFile /> </PrviateRoute>}></Route>
              <Route path="/admin/church" element={<PrviateRoute> <Churches /> </PrviateRoute>}></Route>
              <Route path="/admin/languages" element={<PrviateRoute> <Languages /> </PrviateRoute>}></Route>
              <Route path="/admin/user-list" element={<PrviateRoute> <Users /> </PrviateRoute>}></Route>
              <Route path="/admin/user/:id" element={<PrviateRoute> <User /> </PrviateRoute>}></Route>
              <Route path="/profile/details" element={<PrviateRoute> <Update /> </PrviateRoute>}></Route>
              <Route path="/user/profile" element={<PrviateRoute> <Profile /> </PrviateRoute>}></Route>
              <Route path="/en/dist" element={<PrviateRoute> <LandingPage /> </PrviateRoute>}></Route>


            </Routes>

          </div>

          <AppFooter layoutColorMode={layoutColorMode} />
        </div>





        <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
          layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

        <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
          <div className="layout-mask p-component-overlay"></div>
        </CSSTransition>
      </QueryClientProvider>
    </div>
  );

}

export default App;
