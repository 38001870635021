import React, { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getAllFiles, deleteFile } from './../_services';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Moment from "moment";
import { FileService } from './../_services/FileService';
import { Messages } from 'primereact/messages';
import { ProgressBar } from 'primereact/progressbar';
import { useMutation, useQuery, useQueryClient } from "react-query";




export const FilesList = () => {

  let emptyProduct = {
    id: null,
    name: '',
    image: null,
    description: '',
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: 'INSTOCK'
  };



  const queryClient = useQueryClient();
  const [removeFileDialog, setDeleteProductDialog] = useState(false);
  const [file, setFile] = useState(emptyProduct);
  const [selectedChurchs, setSelectedChurches] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const [totalSize, setTotalSize] = useState(0);


  const msgError = useRef(null);
  const dt = useRef(null);
  const fileService = new FileService();

  const { mutateAsync } = useMutation(deleteFile);
  const { isLoading, data } = useQuery("files", getAllFiles);






  const downloadFile = async (id, path, mimetype) => {
    try {
      fileService.downloadFile(id, path, mimetype);
      //  fileService.countDownload(auth.id, id);

    } catch (error) {
      if (error.response && error.response.status === 400) {
        msgError.current.show([{ severity: 'error', detail: 'Error while downloading file. Try again later', sticky: false, life: 5000 }]);
      }
    }
  };

  const formatDate = (value) => {
    return Moment(value).format("YYYY/MM/DD kk:mm:ss");
  };



  const hideRemoveFileDialog = () => {
    setDeleteProductDialog(false);
  };

  const onDownloadFile = (file) => {
    setFile({ ...file });
    downloadFile(file._id, file.file_path, file.file_mime_type);
  };

  const confirmRemoveFile = (file) => {
    setFile(file);
    setDeleteProductDialog(true);
  };

  const removeFile = async () => {

    try {
      await mutateAsync(file._id);
      queryClient.invalidateQueries('files');
      setDeleteProductDialog(false);
      msgError.current.show([{ severity: 'success', detail: 'File Deleted Successfuly', sticky: false, life: 5000 }]);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        msgError.current.show([{ severity: 'error', detail: 'Error while deleting file.', sticky: false, life: 5000 }]);
      }
    }
  };



  const leftToolbarTemplate = () => {
    return (
      <h5 className="m-0">File Manager</h5>
    )
  }

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;

    console.log(e.files);
    // e.files.forEach(file => {
    //   _totalSize += file.size;
    // });

    setTotalSize(_totalSize);
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          name="file"
          url="/api/files/uploadfile"
          onUpload={onUpload}
          onError={onError}
          onProgress={onProgress}
          accept="application/pdf"
          onSelect={onTemplateSelect}
          label="Import"
          chooseLabel="Import"
          className="mr-2 inline-block"
          maxFileSize={30 * 1024 * 1024}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-download" className="p-button-rounded p-button-success mr-2" onClick={() => onDownloadFile(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmRemoveFile(rowData)} />
      </div>
    );
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
    </div>
  );

  const removeFileDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRemoveFileDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={removeFile} />
    </>
  );
  const onUpload = (e) => {

    let _totalSize = 0;
    e.files.forEach(file => {
      _totalSize += (file.size || 0);
    });
    console.log(_totalSize);
    setTotalSize(_totalSize);

    queryClient.invalidateQueries('files');
    msgError.current.show([{ severity: 'success', detail: 'File Uploaded Successful', sticky: false, life: 5000 }]);
  }

  const onProgress = (e) => {
    console.log(e)
  }

  const onError = (e) => {
    console.log("================================================");
    console.log(e);
    console.log(e.xhr.responseText);
    console.log("================================================");
    msgError.current.show([{ severity: 'error', detail: e.xhr.responseText, sticky: false, life: 5000 }]);
  }




  const dateTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Created</span>
        {formatDate(rowData.created)}
      </>
    );
  }



  if (isLoading) return <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>


  return (
    <div className="grid">

      <div className="col-12">
        <div className="card">
          <Messages ref={msgError} />

          <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
          <DataTable ref={dt} value={data} selection={selectedChurchs} onSelectionChange={(e) => setSelectedChurches(e.value)}
            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} file items"
            globalFilter={globalFilter} emptyMessage="No file found." header={header} responsiveLayout="scroll">
            <Column field="book_name" header="Name" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
            <Column field="book_language" header="Language" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
            <Column field="book_month" header="Month" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
            <Column field="file_size" header="File Size" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
            <Column field="createdAt" header="Uploaded On" sortable body={dateTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
            <Column header="Actions" body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog visible={removeFileDialog} style={{ width: '450px' }} header="Confirm" modal footer={removeFileDialogFooter} onHide={hideRemoveFileDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {file && <span>Are you sure you want to delete <b>{file.name}</b>?</span>}
            </div>
          </Dialog>

        </div>
      </div>
    </div>
  );
}
