import React, { useRef } from 'react';
import { FileUpload } from "primereact/fileupload";
import axios from "axios";
import { Messages } from 'primereact/messages';



export const UploadJsonFile = () => {
    const msgError = useRef(null);


    const checkImageDimension = (event) => {

        const file = event.files[0];
        const reader = new FileReader();


        reader.readAsDataURL(file);
        reader.onload = (e) => {

            let formData = new FormData();
            formData.append('jsonfile', file);
            formData.append('jsonfilesssssssssssss', 'files');


            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/api/files/uploadCountries`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then((response) => {
                console.log("response");
                console.log(response);
                console.log("response");
                if (response.statusText === 'OK') {
                    msgError.current.show([{ severity: 'success', detail: response.data, sticky: false, life: 5000 }]);
                } else {
                    msgError.current.show([{ severity: 'error', detail: response.data, sticky: false, life: 5000 }]);

                }
            }).catch(err => {
                console.log("err====================");
                console.log(err);
                console.log("err======================");
                msgError.current.show([{ severity: 'error', detail: err.message, sticky: false, life: 5000 }]);
            });
        }
    }
    return (
        <div>
            <Messages ref={msgError} />
            <FileUpload id="jsonfile"
                name="jsonfile" mode="advanced" accept="application/json"
                chooseLabel="Upload Country File"
                cancelLabel="Cancel" maxFileSize={3000000}
                invalidFileSizeMessageSummary="{0}: File is too big"
                customUpload={true}
                uploadHandler={checkImageDimension} />

        </div>
    )
}
