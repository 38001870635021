import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { useUserActions } from './../_actions/user.actions';
import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams } from "react-router-dom";


export const VerifyEmail = () => {
    const userActions = useUserActions();
    const [showMessage, setShowMessage] = useState(false);
    const msgError = useRef(null);
    const history = useNavigate();
    const [searchParams] = useSearchParams();

    const EmailStatus = {
        Verifying: 'Verifying',
        Failed: 'Failed'
    }


    const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

    useEffect(() => {


        userActions.verifyEmail(searchParams.get('token'))
            .then((res) => {
                console.log(res);
                setShowMessage(true);
                // naviage to login page
            })
            .catch((err) => {
                console.log(err);
                setEmailStatus(EmailStatus.Failed);
            });
    }, [EmailStatus?.Failed, searchParams, userActions]);




    function onDialogClose() {
        setShowMessage(false);
        history("/login");
    }


    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={onDialogClose} /></div>;


    // const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;


    const getBody = () => {
        switch (emailStatus) {
            case EmailStatus.Verifying:
                return <div>Verifying...</div>;
            case EmailStatus.Failed:
                return <div>Verification failed, you can also verify your account using the <Link to="forgot-password">forgot password</Link> page.</div>;
            default:
            // do nothing
        }
    }

    return (
        <div>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex align-items-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Verify Email</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Verification successful, you can now login.
                    </p>
                </div>
            </Dialog>
            <div className="flex justify-content-center">
                <div className="card">
                    <Messages ref={msgError} />
                    <h5 className="text-center">Verify Email</h5>
                    <div>{getBody()}</div>
                </div>
            </div>
        </div>
    )



}


