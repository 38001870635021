import React from 'react';
import { Chart } from 'primereact/chart';


export const DoughnutChart = ({ chartData, options }) => {
    return (
        <>
            <Chart type="doughnut" data={chartData} options={options} />
        </>
    )
}
