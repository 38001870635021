import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import { LandingPage } from './components/LandingPage';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { ForgotPassword } from './components/ForgotPassword';
import { Notfound } from './components/Notfound';
import { AccessDenied } from './components/AccessDenied';
import { VerifyEmail } from './components/VerifyEmail';
import { ResetPassword } from './components/ResetPassword';
import { Signup } from './components/Signup';
import { QueryClient, QueryClientProvider } from "react-query";
import axios from 'axios';


axios.defaults.headers.common.Authorization = "Bearer " + localStorage.getItem('token');
axios.defaults.headers.common.Accept = "application/json";


axios.interceptors.request.use((request) => {


  return request;
});

axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {

  if ([401, 403].includes(error.response.status)) {


    localStorage.clear();
    localStorage.removeItem('user');

    // if (navigate) {
    //   navigate('/login');
    // } else {
    window.location.href = '/login';
    // }
  }

  console.log(error);
  console.log(error.response);
  return Promise.reject(error);
});



const queryClient = new QueryClient();


//navigate('/home');

ReactDOM.render(
  <BrowserRouter>
    <Suspense>
      <RecoilRoot>
        <ScrollToTop>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/*" element={<App />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/access" element={<AccessDenied />} />
              <Route path="/account/register" element={<Register />} />
              <Route path="/account/reset-password" element={<ResetPassword />} />
              <Route path="/account/verify-email" element={<VerifyEmail />} />
              <Route path="/account/forgot-password" element={<ForgotPassword />} />
              <Route path="/en/dist" element={<LandingPage />} />



              <Route path="*" element={<Notfound />}></Route>
            </Routes>
          </QueryClientProvider>
        </ScrollToTop>
      </RecoilRoot>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

