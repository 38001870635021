import React from 'react';
import { Button } from "primereact/button";

import { useNavigate } from 'react-router-dom';



export const Notfound = () => {

    const history = useNavigate();

    const goDashboard = () => {
        history.push('/');
    }

    return (
        <div>
            <div className="exception-body notfound-body" >

                <div className="exception-container">
                    <img src="assets/layout/images/logo-dark.png" alt="apollo-layout" />
                    <h1>Not Found</h1>
                    <p>
                        Resource is not found.
                    </p>

                    <Button label="Go To Dashboard" icon="pi pi-arrow-left" onClick={goDashboard} />
                </div>
            </div>
        </div>
    )
}
